import React from 'react';
import Layout from '../../../templates/layout';
import InvoicesList from '../../../components/user/invoices/InvoicesList';

const InvoicesPage = props => (
  <Layout>
    <InvoicesList/>
  </Layout>
);

export const Head = () => <title>Faktury | Zapłatomat</title>;

export default InvoicesPage;
