import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Alert
} from '@mui/material';
import Paginator from '../../Paginator';
import StyledTableCell from '../../utils/StyledTableCell';
import BasePageContent from '../../utils/BasePageContent';
import formatAmount from '../../utils/formatAmount';
import { navigate } from 'gatsby';
import { styled } from '@mui/material/styles';

function mapStateToProps(state) {
  return {
    invoices: state.getInvoices,
  };
}

const PREFIX = "InvoicesList";
const classes = {
  root: `${PREFIX}-root`,
  tableRow: `${PREFIX}-tableRow`,
}
const RootPaginator = styled(Paginator)(({theme}) => ({
  [`& .${classes.root}`]: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
  },
  [`& .${classes.tableRow}`]: {
    cursor: 'pointer',
  },
}));

class InvoicesList extends Component {
  renderInvoiceRow = invoice => {
    const {
      code,
      number,
      billingPeriod,
      issueDate,
      amount,
      currency,
    } = invoice.data;

    return (
      <TableRow
        hover
        key={code}
        className={classes.tableRow}
        onClick={() => navigate(`/user/invoices/${code}`)}
      >
        <TableCell>{code}</TableCell>
        <TableCell>{number}</TableCell>
        <TableCell>{billingPeriod}</TableCell>
        <TableCell>{issueDate}</TableCell>
        <TableCell>
          {formatAmount(amount, currency)}
        </TableCell>
      </TableRow>
    );
  };

  render() {
    return (
      <RootPaginator
        resource={this.props.invoices}
        getResource={this.props.actions.getInvoices}
        render={(invoices, paginationFooter, loading, error) => (
          <BasePageContent loading={loading}>
            <Box textAlign='center'>
              {error ? (
                <Alert severity='error'>{error}</Alert>
              ) : (
                <>
                  {invoices?.length ? (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Kod</StyledTableCell>
                          <StyledTableCell>Numer</StyledTableCell>
                          <StyledTableCell>Okres rozliczeniowy</StyledTableCell>
                          <StyledTableCell>Data wystawienia</StyledTableCell>
                          <StyledTableCell>Kwota</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoices.map((invoice, idx) =>
                          this.renderInvoiceRow(invoice, idx)
                        )}
                      </TableBody>
                    </Table>
                  ) : (
                    <Typography>
                      Nie wystawiliśmy Ci dotychczas żadnej faktury
                    </Typography>
                  )}
                </>
              )}
            </Box>
            <div className={classes.root}>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item>{paginationFooter()}</Grid>
              </Grid>
            </div>
          </BasePageContent>
        )}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesList);
